<template>
  <v-carousel
    cycle
    :height="height"
    hide-delimiter-background
    show-arrows-on-hover
    style="max-width: 100%!important;"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
      :src="slide.src"
      :to="{name: 'Productos', query: { filtro: slide.to }}"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data () {
    return {
      slides: [
        {
          src: require('@/assets/landing-img/slide-varilla-corrugada.jpg'),
          to: 'Varilla'
        },
        {
          src: require('@/assets/landing-img/teja-galvasteel.jpg'),
          to: 'Derivados de Alambrón'
        },
        {
          src: require('@/assets/landing-img/slide-alambron.jpg'),
          to: 'Alambrón'
        },
      ]
    }
  },
  computed: {
    height() {
      switch(this.$vuetify.breakpoint.name) {
        case 'xs': return '150'
        case 'sm': return '200'
        case 'md': return '250'
        case 'lg': return '350'
        case 'xl': return '500'
      }
    }
  }
}

</script>
